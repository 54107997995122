$.ajaxSetup({
	headers: {
		'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
	}
});

(function($) {
	$.fn.serializeFiles = function() {
		var form = $(this),
			formData = new FormData()
		formParams = form.serializeArray();

		$.each(form.find('input[type="file"]'), function(i, tag) {
			$.each($(tag)[0].files, function(i, file) {
				formData.append(tag.name, file);
			});
		});

		$.each(formParams, function(i, val) {
			formData.append(val.name, val.value);
		});

		return formData;
	};
})(jQuery);

ko.observableArray['fn'].get = function (index) {
	var underlyingArray = this.peek();

	return underlyingArray[index] || undefined;
};

ko.bindingHandlers.touchspin = {
	init: function (element, valueAccessor) {
		var $el = $(element), max = ko.unwrap(valueAccessor());

		$LAB.script("/js/touchspin.min.js").wait(function () {
			$el.addClass('touchspin').TouchSpin({
				min: !parseInt(max) ? 0 : 1,
				max: max
			}).on("touchspin.on.ko touchspin.on.stopspin", function () {
				var $prev = $el.prev().prev().removeClass('disabled'),
					$next = $el.next().next().removeClass('disabled');

				if (element.value == 1) {
					$prev.addClass('disabled');
				}

				if (element.value == max) {
					$next.addClass('disabled');
				}

				if (!parseInt(max)) {
					$prev.addClass('disabled');
					$el.attr('disabled', 'true');
				}
			}).trigger('touchspin.on.ko');
		});
	}
};


ko.subscribable.fn.subscribeChanged = function (callback) {
	var savedValue = this.peek();
	return this.subscribe(function (latestValue) {
		var oldValue = savedValue;
		savedValue = latestValue;
		callback(latestValue, oldValue);
	});
};

ko.bindingHandlers.initializeValue = {
	init: function (element, valueAccessor) {
		var value = element.value || element.getAttribute('value') || undefined;

		switch (element.getAttribute('data-bind-type')) {
			case 'int':
				value = parseInt(value);
				break;

			case 'json':
				value = JSON.parse(value);
				break;

			case 'bool':
				value = value === 'true';
				break;
		}

		valueAccessor()(value);
	}
};

ko.field = function (def) {
	var ret = $.isArray(def) ? ko.observableArray(def) :
		ko.observable(def);

	ret.error = ko.observable(false);
	ret.subscribe(function () {
		ret.error(false);
	});

	return ret;
};

ko.bindingHandlers.price = {
	update: function (element, valueAccessor) {
		element.innerHTML = String(valueAccessor()()).replace(/(\d+)(\d{3})/, '$1 $2');
	}
};

ko.bindingHandlers.init = {
    init: function (el, valueAccessor) {
        valueAccessor()(el.value)
    }
};

ko.bindingHandlers.cost = {
    update: function (el, valueAccessor) {
        const set = function() {
            return valueAccessor()(el.options[el.selectedIndex].dataset.cost);
        }

        set()
        el.addEventListener('change', set)
        ko.utils.domNodeDisposal.addDisposeCallback(el, function() {return el.removeEventListener('change', set)})
    }
};

ko.bindingHandlers.trueFalseRadioButton = {
        init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext)
        {
            // event handler for the element change event
            var changeHandler = function ()
            {
                var elementValue = $(element).val();
                var observable = valueAccessor();      // set the observable value to the boolean value of the element value
                observable($.parseJSON(elementValue));
            };    // register change handler for element
            ko.utils.registerEventHandler(element,
                "change",
                changeHandler);
        },
        update: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext)
        {
            var elementValue = $.parseJSON($(element).val());
            var observableValue = ko.utils.unwrapObservable(valueAccessor());    if (elementValue === observableValue)
        {
            element.checked = true;
        }
        else
        {
            element.checked = false;
        }
        }
    };

// Выбор группы в конкретном курсе
// Не позволяет менять направления или курс
$('#jgntFormEnrol').each(function () {

    var $form = $(this),
        $guestsCountInput = $('input[name=count]'),
        $oldPrice = $form.find("#jgntTotalOldPrice"),
        $oldPriceBr = $form.find('br.oldPriceBr'),
        $totalPrice = $form.find("#jgntTotal"),
        $totalOldPrice = $form.find("#jgntTotalOldPrice");


    var discounts = {
        'promocode': 0,
        'certificate': 0,
    },

    guestsCount = function () {
        return Number($guestsCountInput.val())
    },

    initialPrice = function () {
        var $groupInput = $('input[name=group]:checked');
        var guestsCountValue = guestsCount();
        if (guestsCountValue === 1) {
            return Number($groupInput.data('total'));
        } else if (guestsCountValue === 2) {
            return Number($groupInput.data('price-for-two'));
        } else {
            return Number($groupInput.data('price')) * guestsCount;
        }
    },

    discountedPrice = function () {
        var initialPriceValue = initialPrice();
        return Math.max(0,
            initialPriceValue
            - (initialPriceValue / 100 * discounts.promocode)
            - discounts.certificate
        );
    }

	var $promocodeBlock = $('fieldset.promocode');
	$promocodeBlock.each(function(){
		var $fieldset = $(this),
			$input = $fieldset.find('input'),
			$button = $fieldset.find('a.btn'),
			$error = $fieldset.find('p.text-danger'),
			//$paymentType = $('input[name=payment_type]:checked'),
			$prefill = $fieldset.find('.promocode-prefill'),
			$price = $('#jgntTotal');

		var checkPromoCode = function(e) {

			if (e) {
                e.preventDefault();
            }
			reset();

			$.ajax({
                type: 'POST',
                url: "/school/course/check_promocode",
                data: {
                    code: $input.val(),
					group_id: $('input[name=group]:checked').val(),
					payment_type: $('input[name=payment_type]:checked').val(),
                },
                dataType: 'json',
                success: function(e) {
                    $fieldset.addClass("has-success");

                    discounts.promocode = e.discount;
                    $price.html(discountedPrice());
                },
                error: function(e) {
                    reset();

                    $fieldset.addClass("has-danger");
					$error.text(e.responseJSON.error).show();
					$input.val('');
                }

            })
		},
		reset = function() {
            $error.text('').hide();
            $fieldset.removeClass('has-danger has-success has-warning');
            discounts.promocode = 0;
            $price.html(discountedPrice());
		};

        reset();
		$button.on('click', checkPromoCode);
        $input.on("change input", function () {
            reset();
            $fieldset.addClass('has-warning');
        }).on("keydown", function (e) {
            if (e.which === 13) {
                e.preventDefault();
                checkPromoCode();
            } else if (e.which === 32) {
            	e.preventDefault();
            	return false;
			}

        });
        $prefill.on('click', function(e){
            e.preventDefault();
            var prefillCode = $(this).data('prefill');
            $input.val(prefillCode);
            checkPromoCode();
        })
	});

    var $certBlock = $('.block_sert');
    $certBlock.each(function(){
        var
            $certForm = $(this).find('.block_sert_form'),
            $fieldset = $certForm.find('fieldset'),
            $certInfo = $(this).find('.block_sert_info'),
            $certInfoDiscount = $certInfo.find('.big'),
            $certInfoResetBtn = $certInfo.find('.btn'),
            $input = $certForm.find('input[name=certificate]'),
            $button = $certForm.find('a.btn'),
            $error = $certForm.find('p.text-danger'),
            //$paymentType = $('input[name=payment_type]:checked'),
            $price = $('#jgntTotal');

        var checkCertificate = function(e) {

                if (e) {
                    e.preventDefault();
                }
                reset();

                $.ajax({
                    type: 'POST',
                    url: "/school/api/checkCertificate",
                    data: {
                        cert_code: $input.val(),
                        group_id: $('input[name=group]:checked').val(),
                        payment_type: $('input[name=payment_type]:checked').val(),
                    },
                    dataType: 'json',
                    success: function(data) {
                        if (data['status']) {
                            $fieldset.addClass("has-success");

                            discounts.certificate = data.discount;
                            $price.html(discountedPrice());

                            $certInfoDiscount.html(data.discount);
                            $certInfo.show();
                            $certForm.hide();
                        } else {
                            $fieldset.addClass("has-danger");
                            $error.text("Сертификат не существует или истек срок его действия.").show();
                            $input.val('');

                            discounts.certificate = 0;
                            $price.html(discountedPrice());
                        }
                    },
                    error: function(e) {
                        reset();

                        $fieldset.addClass("has-danger");
                        $error.text(e.responseJSON.error).show();
                        $input.val('');
                    }

                })
            },
            reset = function() {
                $certInfo.hide();
                $error.text('').hide();
                $fieldset.removeClass('has-danger has-success has-warning');
                discounts.certificate = 0;
                $price.html(discountedPrice());
            };

        reset();
        $button.on('click', checkCertificate);
        $certInfoResetBtn.on('click', function(){
            reset();
            $certInfo.hide();
            $certForm.show();
            $input.val('');
        });
        $input.on("change input", function () {
            reset();
            $fieldset.addClass('has-warning');
        }).on("keydown", function (e) {
            if (e.which === 13) {
                e.preventDefault();
                checkPromoCode();
            } else if (e.which === 32) {
                e.preventDefault();
                return false;
            }

        });
    });

	ko.applyBindings(new function () {
        var that = this;

		that.phone = ko.observable().extend({formField: true});
        that.maxUsersCount = ko.observable();
        that.currentUsersCount = ko.observable();
        that.guestInformation = ko.observable();
        that.guests = ko.observableArray([]);
        that.groupSelected = ko.observable(1);

        that.guestsCounterVisible = ko.pureComputed(function(){
            return that.groupSelected() && $('[name=area]:checked').val() == 8 && (!!$('input[name=group]:checked').data('price-for-two'));
        });

        that.currentUsersCount.subscribe(function (newValue) {
            var length = that.guests().length;

            if (length < newValue) {
                that.guestInformation(true);

                for (var i = 1; i < newValue - length; i++) {
                    that.guests.push({
                        firstName: ko.observable(),
                        familyName: ko.observable(),
                        // emailQuest: ko.observable()
                    });
                }
            }
            else {
                that.guests(that.guests().slice(0, newValue - 1));
            }
        });

	}, this);

    var $clickedSubmitButton = null;

    var updatePriceBlock = function(){
        $totalPrice.html( discountedPrice() );
        var $groupInput = $('input[name=group]:checked'),
            oldPrice = $groupInput.data("oldprice"),
            totalPrice = $groupInput.data("total");
        if (oldPrice && oldPrice > totalPrice && guestsCount() == 1) {
            $oldPrice.html(oldPrice).show();
            $oldPriceBr.show();
        } else {
            $totalOldPrice.html("").hide();
            $oldPriceBr.hide();
        }
    };
    $form.find(".submitBtn").on('click', function (e) {
        $clickedSubmitButton = $(this);
    });

	$form.submit(async function (e) {

        e.preventDefault();

		jgntValidationConfigure();
		jgntNod.performCheck();

		if (jgntNod.areAll('valid')) {
		    var $submitBtns = $form.find('button[type=submit]');

            // send conversion event
            sendConversionEvent();
            // send ecom data
            if (typeof window.ecomPurchaseData !== "undefined") {
                var $groupInput = $('input[name=group]:checked'),
                    totalPrice = $groupInput.data("total");

                window.ecomPurchaseData.ecommerce.purchase.products[0].price = totalPrice;

                dataLayer.push(window.ecomPurchaseData);
                console.warn("Sended to datalayer", JSON.stringify(window.ecomPurchaseData, null, 1));
                await new Promise(r => setTimeout(r, 500));

            }

		    $submitBtns
				.attr('disabled', 'disabled');

            $($clickedSubmitButton)
				.addClass('loading');

            var data = $form.serializeFiles();
            data.append('request_type', $clickedSubmitButton ? $clickedSubmitButton.data('request-type') : 'talk');

			$.ajax({
				method: 'post',
				url: window.location,
				processData: false,
				contentType: false,
				dataType: 'json',
				data: data,
				success: function (data) {

					$submitBtns
						.attr('disabled', false)
						.removeClass('loading');

                    var redirectCallback = function () {
                        document.location.href = data.redirect_url;
                    };

				    if ($("[name='form_enrol']", $form).val() == 1) {
                        sendMetrika('Buy_Course', 'send', 'event', 'BuyCourse', 'Form', window.location.pathname.split('/')[3], redirectCallback);
                    } else {
                        sendMetrika('Course_WaitingList', 'send', 'event', 'Notify', 'Form', window.location.pathname.split('/')[3], redirectCallback);
                    }
				},
				error: function (xhr) {

					$submitBtns
						.attr('disabled', false)
						.removeClass('loading');

					if (xhr.status == 403) {
						window['authorized'] = function () {
							$('#jgntFormEnrol').submit();
						};

						jgntShowAuth(false, 'course_buy');
					}

					// 422 Unprocessable Entity
					if (xhr.status == 422) {
						for (var field in xhr.responseJSON) {
							if (xhr.responseJSON.hasOwnProperty(field)) {
								$('[name='+field+']').parents('fieldset')
									.removeClass('has-success')
									.addClass('has-danger')
							}
						}

						$('html, body').animate({ 'scrollTop': $('fieldset.has-danger').first().offset().top });
					}
				}
			});
		}

		return false;
	});

	$('[name="area"]').change(function () {
		$('#jgntFormEnrol_Hide').toggle(this.value != 1);
	});

	$('[name="group"]').change(function(){
        $guestsCountInput.val(1).triggerHandler('change');
        $guestsCountInput.triggerHandler('touchspin.on.ko');
		$promocodeBlock.toggle($(this).data('has-promocode') == 1);
        updatePriceBlock();
	})

    $guestsCountInput.change(function(){
        updatePriceBlock();
    })

	$(function () {
        $('[name="area"]:checked').triggerHandler('change');
        $('[name="course"]:checked').triggerHandler('change');
        $('[name="group"]:checked').triggerHandler('change');

        var isWaitingList = $("[name='form_enrol']", $form).val() == 2;
        var isManagement = $('[name="area"]', $form).val() == 4;

        $certBlock.toggle(!isWaitingList); // hide cert for waiting list

        if (isWaitingList) {
            var $city = $('[name="city"]');
            $city.val('-');
            $city.parent('fieldset').toggle(false);

            $birthdate = $('[name="birthdate"]');
            $birthdate.val('-');
            $birthdate.parent('fieldset').toggle(isManagement || !isWaitingList);
        }
    });
});

/**
 * Покупка мастеркласса (отличается от покупки курса)
 */
$('#jgntFormBuyNew').each(function () {

	$('fieldset.promocode').each(function(){
		var $fieldset = $(this),
			$input = $fieldset.find('input'),
			$button = $fieldset.find('a.btn'),
			$error = $fieldset.find('p.text-danger'),
			//$paymentType = $('input[name=payment_type]:checked'),
			$prefill = $fieldset.find('.promocode-prefill'),
			$price = $('#jgntTotal');

		var checkPromoCode = function(e) {

				if (e) {
					e.preventDefault();
				}
				reset();

				$.ajax({
					type: 'POST',
					url: "/school/course/check_promocode",
					data: {
						code: $input.val(),
						payment_type: $('input[name=payment_type]:checked').val()
					},
					dataType: 'json',
					success: function(e) {
						$fieldset.addClass("has-success");
						$price.html(Math.round($('input[name=group]:checked').data('total') - ($('input[name=group]:checked').data('total') / 100 * e.discount)));
					},
					error: function(e) {
						$fieldset.addClass("has-danger");
						$error.text(e.responseJSON.error).show();
						$input.val('');
					}

				})
			},
			reset = function() {
				$error.text('').hide();
				$fieldset.removeClass('has-danger has-success has-warning');
				$price.html($('input[name=group]:checked').data('total'));
			};

		reset();
		$button.on('click', checkPromoCode);
		$input.on("change input", function () {
			reset();
			$fieldset.addClass('has-warning');
		}).on("keydown", function (e) {
			if (e.which === 13) {
				e.preventDefault();
				checkPromoCode();
			} else if (e.which === 32) {
				e.preventDefault();
				return false;
			}

		});
		$prefill.on('click', function(e){
			e.preventDefault();
			var prefillCode = $(this).data('prefill');
			$input.val(prefillCode);
			checkPromoCode();
		})
	});

    var form = $(this);

	ko.applyBindings(new function () {
		var that = this;

		that.ticketShipment = ko.observable();
		that.price = ko.observable();
		that.currentUsersCount = ko.observable();
		that.maxUsersCount = ko.observable();

		that.guestInformation = ko.observable();
		that.guests = ko.observableArray([]);

		that.giftHasSender = ko.observable();
		that.giftSenderFirstName = ko.observable();
		that.giftSenderFamilyName = ko.observable();
		that.giftRecipientFirstName = ko.observable();
		that.giftRecipientFamilyName = ko.observable();
		that.delivery = ko.observable();
        that.deliveryId = ko.observable(0);
        that.deliveryCost = ko.observable(0);
		that.deliveryPhone = ko.observable();
		that.deliveryStreet = ko.observable();
		that.deliveryHouse = ko.observable();
		that.deliveryHouseVariant = ko.observable();
		that.deliveryRoom = ko.observable();
		that.deliveryText = ko.observable();

		that.certificateProcessing = ko.observable(false);
		that.certificateHasError = ko.observable(false);
		that.certificateVerified = ko.observable(false);
		that.certificateDiscount = ko.observable(0);
        that.certificateRealDiscount = ko.observable(0);
        that.certificateRemainder = ko.observable(0);
		that.certificate = ko.observable();

		that.promocodeProcessing = ko.observable(false);
		that.promocodeHasError = ko.observable(false);
		that.promocodeVerified = ko.observable(false);
		that.promocodeDiscount = ko.observable(0);
		that.promocodeDiscountInRubles = ko.observable(0);
		that.promocode = ko.observable();

		that.phone = ko.observable();
		that.name = ko.observable();
		that.family = ko.observable();
		that.email = ko.observable();

		that.discountMastercardProcessing = ko.observable(false);
		that.discountMastercardHasError = ko.observable(false);
		that.discountMastercardVerified = ko.observable(false);
		that.discountMastercardValue = ko.observable(0);
		that.discountMastercardType = ko.observable();
		that.discountMastercardValueInRubles = ko.observable(0);
		that.discountMastercardValueInRublesOrigin = ko.observable(0);
		that.discountMastercard = ko.observable();
		that.discountMastercardId = ko.observable();
		that.discountMastercardBinNumber = ko.observable();
		that.discountMastercard.subscribe(function (checked) {
			if (checked) {
				that.removeCertificate();
				that.removePromocode();
			} else {
				that.removeDiscountMastercard();
			}
		});

		that.checkCertificate = function () {
			that.certificateProcessing(true);

			$.post('/school/api/checkCertificate', {
				cert_code: that.certificate(),
                group_id: form.data('group-id'),
                payment_type: 'true', // full payment
			}).done(function (data) {
				that.certificateProcessing(false);
				that.certificateHasError(false);
				that.certificateVerified(false);

				if (data['status']) {
					that.certificateDiscount(data['discount']);
					that.certificate(data['certificate']);
					that.certificateVerified(true);
				}
				else {
					that.certificateHasError(true);
				}
			});
		};
		that.removeCertificate = function () {
			that.certificateVerified(false);
			that.certificate('');
		};

        that.certificateRealDiscount = ko.pureComputed(function () {
            return Math.min(that.currentUsersCount() * that.price() +
                (that.delivery() ? Number(that.deliveryCost()) : 0),
                (that.certificateVerified() && !that.discountMastercardVerified() ? that.certificateDiscount() : 0));
        });

        that.certificateRemainder = ko.pureComputed(function () {
            return that.certificateVerified() ? (that.certificateDiscount() - that.certificateRealDiscount()) : 0;
        });

		that.checkPromocode = function () {
			that.promocodeProcessing(true);

			$.post('/school/course/check_promocode', {
				code: that.promocode(),
				group_id: form.data('group-id'),
				payment_type: 'true', // full payment
			}).done(function (data) {
				that.promocodeProcessing(false);
				that.promocodeHasError(false);
				that.promocodeVerified(false);

				if (data['error']) {
					that.promocodeHasError(true);
					$('#promocode-error').text(data.error);
				}
				else {
					that.promocodeDiscount(data['discount']);
					that.promocodeVerified(true);
				}
			})
			.fail(function(xhr){
				$('#promocode-error').text(xhr.responseJSON.error);
				that.promocodeProcessing(false);
				that.promocodeVerified(false);
				that.promocodeHasError(true);

			});
		};
		that.removePromocode = function () {
			that.promocodeVerified(false);
			that.promocode('');
		};

		// При изменении типа доставки всегда отключаем доставку из стоимости
		that.ticketShipment.subscribe(function () {
			that.delivery(false);
		});

		that.currentUsersCount.subscribe(function (newValue) {
			var length = that.guests().length;

			if (length < newValue) {
				that.guestInformation(true);

				for (var i = 1; i < newValue - length; i++) {
					that.guests.push({
						firstName: ko.observable(),
						familyName: ko.observable(),
						// emailQuest: ko.observable()
					});
				}
			}
			else {
				that.guests(that.guests().slice(0, newValue - 1));
			}

			if (that.discountMastercardVerified()) {
				that.discountMastercardValueInRubles(newValue * that.discountMastercardValueInRublesOrigin());
			}
		});

		that.totalPrice = ko.pureComputed(function () {
			return Math.max(0, that.currentUsersCount() * that.price() +
				(that.delivery() ? Number(that.deliveryCost()) : 0) -
				(that.certificateVerified() && !that.discountMastercardVerified() ? that.certificateDiscount() : 0) -
				(that.promocodeVerified() && !that.discountMastercardVerified() ? that.promocodeDiscountInRubles() : 0) -
				(that.discountMastercardVerified() ? that.discountMastercardValueInRubles() : 0)
			);
		});

		that.promocodeDiscountInRubles = ko.pureComputed(function () {
			var total = that.currentUsersCount() * that.price();
			return Math.ceil(total / 100 * that.promocodeDiscount())
		});


		that.termsAccepted = ko.observable(false);
		that.hasErrors = ko.observable(false);

		that.mastercardCheckIsRequired = ko.observable(false);
		that.mastercardNumber = ko.observable();

		that.doOrder = async function () {

			that.hasErrors(false);
			jgntValidationConfigure();
			jgntNod.performCheck();

			if (!jgntNod.areAll('valid')) {
				that.hasErrors(true);
				return;
			}

			if (!that.termsAccepted()) {
				return;
			}

			var params = {
				count: that.currentUsersCount(),
				certificate: that.certificate(),
				promocode: that.promocode(),
				ticketShipment: that.ticketShipment(),
				phone: that.phone(),
				name: that.name(),
				family: that.family(),
				email: that.email(),
			};

			if (that.discountMastercardVerified()) {
				params['discountId'] = that.discountMastercardId() || false;
				params['discountBinNumber'] = that.discountMastercardBinNumber() || false;
			}

			switch (that.ticketShipment()) {
				case 'email':
					if (that.guestInformation()) {
						params['guests'] = that.guests().map(function (guest) {
							return {
								firstName: guest.firstName(),
								familyName: guest.familyName(),
								// emailQuest: guest.emailQuest()
							}
						});
					}

					break;

				case 'gift':
					params['recipientFirstName'] = that.giftRecipientFirstName();
					params['recipientFamilyName'] = that.giftRecipientFamilyName();

					if (that.giftHasSender()) {
						params['senderFirstName'] = that.giftSenderFirstName();
						params['senderFamilyName'] = that.giftSenderFamilyName();
					}

					if (that.delivery()) {
						params['delivery'] = that.delivery() | 0;
						params['deliveryPhone'] = that.deliveryPhone();
						params['deliveryStreet'] = that.deliveryStreet();
						params['deliveryHouse'] = that.deliveryHouse();
						params['deliveryHouseVariant'] = that.deliveryHouseVariant();
						params['deliveryRoom'] = that.deliveryRoom();
						params['deliveryText'] = that.deliveryText();
                        params['deliveryTariffId'] = that.deliveryId();
					}

					break;
			}

			// Check Mastercard payment card
			if ([571, 607, 608, 609, 610].indexOf($(form).data('group-id')) != -1) {
				that.mastercardCheckIsRequired(true);

                if (!that.mastercardNumber() || !/^(5)(\d){3}$/.test(that.mastercardNumber())) {

                    if( $("body").hasClass("modal-open") )
                        return;

					var elModal = $('#jgntModalMiddle');
					elModal.find(".modal-title").html( "Только для держателей Mastercard" );
					elModal.attr( 'class', 'jgnt_modal_middle modal fade' );
					elModal.modal('show');

					var strContainer = elModal.find('.modal-body');

					strContainer.html($('#mastercardCheckForm').html());

					var $error = strContainer.find('p.error');

					$error.hide();

					strContainer.on('click', 'button.submit', function(){

						var $input = strContainer.find('input[type=text]').eq(0),
							inputVal = $input.val();

						that.mastercardNumber(inputVal);

						var isnum = /^(5)(\d){3}$/.test(that.mastercardNumber());

						if (!isnum) {
							$input.focus();
							$error.show();
						} else {
							$error.hide();
                            elModal.modal('hide');
                            $('#jgntModalMiddle').on('hidden.bs.modal', function (e) {
                                that.doOrder();
                            });
						}
					});
                	return;
				}
			}


			// All additional fields are sent into additional_fields[] array
            var additionalFields = $("input[name^='additional_fields']").get();
			if (additionalFields.length) {
                params['additional_fields'] = {};
                additionalFields.forEach(function(item) {
                    var $item = $(item);
                    switch ($item.attr('type')) {
                        case "checkbox":
                        case "radio":
                            if (!$item.is(':checked')) return;
                            break;
                    }
                    var paramName = $item.attr('name').replace(/^additional_fields\[(\w+)\]$/, '$1');
                    var paramValue = $item.val();
                    params['additional_fields'][paramName] = paramValue;
                });
            }

            // send conversion event
            sendConversionEvent();

            // send ecom data
            if (typeof window.ecomPurchaseData !== "undefined") {
                var $groupInput = $('input[name=group]:checked'),
                    totalPrice = $groupInput.data("total");

                window.ecomPurchaseData.ecommerce.purchase.products[0].quantity = that.currentUsersCount();

                dataLayer.push(window.ecomPurchaseData);
                console.warn("Sended to datalayer", JSON.stringify(window.ecomPurchaseData, null, 1));
                await new Promise(r => setTimeout(r, 500));

            }

			$.ajax({
				method: 'post',
				url: window.location,
				dataType: 'json',
				data: params,
				success: function (data) {
					if (typeof data.html != "undefined") {
                        var $modal = $('#jgntSystemModalMiddle');
                        $('#jgntModalMiddle').modal('hide');
                        $modal.modal('show').find('.modal-body')
                            .html(data.html);
                    } else if (typeof data.redirect != "undefined") {
						document.location.href = data.redirect;
					}
				},
				error: function (xhr) {
					if (xhr.status == 403) {
						window['authorized'] = function () {
							that.doOrder();
						};

						jgntShowAuth(false, 'mk_buy');
					}
				}
			});
		}

		that.checkDiscountMastercard = function () {
			that.discountMastercardProcessing(true);

			$.post('/school/api/checkDiscountByMastercard', {
				group_id: form.data('group-id'),
				discountMastercardId: that.discountMastercardId(),
				discountMastercardBinNumber: that.discountMastercardBinNumber()
			}).done(function (data) {
				that.discountMastercardProcessing(false);
				that.discountMastercardHasError(false);
				that.discountMastercardVerified(false);

				if (data['status']) {
					that.removeCertificate();
					that.removePromocode();

					that.discountMastercardValue(data['discount']);
					that.discountMastercardValueInRubles(that.currentUsersCount() * data['discountInRubles']);
					that.discountMastercardValueInRublesOrigin(data['discountInRubles']);
					that.discountMastercardType(data['discountType']);
					that.discountMastercardVerified(true);
				}
				else {
					that.discountMastercardHasError(true);
				}
			});
		};

		that.removeDiscountMastercard = function () {
			that.discountMastercardVerified(false);
			that.discountMastercardBinNumber('');
		};

	}, this);


});

/**
 * Запись в лист ожидания для мастеркласса, лектория
 */
$('#jgntFormWaiting').each(function () {
	var $form = $(this);

	$form.find('input[name="count"]').keydown(function(event){
		if ($(this).val().length === 2) {
			$(this).val(
				$(this).val().slice(0,-1)
			);
		}
	});

	ko.applyBindings(new function () {
		this.phone = ko.observable().extend({formField: true});
	}, this);

	$form.submit(function () {
		jgntValidationConfigure();
		jgntNod.performCheck();

		if (jgntNod.areAll('valid')) {
			var $submitBtn = $form.find('button[type=submit]');

			$submitBtn
				.attr('disabled', 'disabled')
				.addClass('loading');

            sendConversionEvent()

			$.ajax({
				method: 'POST',
				url: window.location,
				processData: false,
				contentType: false,
				dataType: 'json',
				data: $form.serializeFiles(),
				success: function (data) {
					$submitBtn
						.attr('disabled', false)
						.removeClass('loading');

					sendMetrika('Course_WaitingList', 'send', 'event', 'Notify', 'Form', window.location.pathname.split('/')[3], function () {
						if (data.redirect_url !== undefined) {
							document.location.href = data.redirect_url;
						}
					});
				},
				error: function (xhr) {
					$submitBtn
						.attr('disabled', false)
						.removeClass('loading');

					if (xhr.status === 403) {
						window['authorized'] = function () {
							$('#jgntFormWaiting').submit();
						};

						jgntShowAuth(false, 'mk_buy');
					}

					// 422 Unprocessable Entity
					if (xhr.status === 422) {
						for (var field in xhr.responseJSON) {
							if (xhr.responseJSON.hasOwnProperty(field)) {
								$('[name=' + field + ']').parents('fieldset')
									.removeClass('has-success')
									.addClass('has-danger')
							}
						}

						$('html, body').animate({'scrollTop': $('fieldset.has-danger').first().offset().top});
					}
				}
			});
		}

		return false;
	});
});

$("#jgntFormEnrol").each(function () {
	var $form = $(this);

	$form.find('[name="course"]').change(function () {
        $form.find('.terms').hide();
        $('.terms-' + this.value).show();

		$('.allstars-business').toggle($(this).attr('value') == 556);
    });



	$form.trigger('change');
});
